import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import AppProvider from 'store/provider';
import Layout from './src/components/transition';

const breakpointValues = {
	xs: 0,
	sm: 425,
	md: 768,
	lg: 1024,
	xl: 1440,
};
const transitionDelay = 500;

const theme = createMuiTheme({ breakpoints: { values: breakpointValues } });

// React Context in Browser
// eslint-disable-next-line react/prop-types
export const wrapRootElement = ({ element }) => {
	return (
		<AppProvider>
			<MuiThemeProvider theme={theme}>{element}</MuiThemeProvider>
		</AppProvider>
	);
};

export const shouldUpdateScroll = ({
	routerProps: { location },
	getSavedScrollPosition,
}) => {
	if (location.action === 'PUSH') {
		window.setTimeout(() => window.scrollTo(0, 0), transitionDelay);
	} else {
		const savedPosition = getSavedScrollPosition(location);
		window.setTimeout(
			() => window.scrollTo(...(savedPosition || [0, 0])),
			transitionDelay
		);
	}
	return false;
};

// Page Transitions
// eslint-disable-next-line react/prop-types
export const wrapPageElement = ({ element, props }) => {
	return <Layout {...props}>{element}</Layout>;
};
