import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const duration = 0.5;

const variants = {
	initial: {
		opacity: 0,
	},
	enter: {
		opacity: 1,
		transition: {
			duration: duration,
			delay: duration,
			when: 'beforeChildren',
		},
	},
	exit: {
		opacity: 0,
		transition: { duration: duration },
	},
};

// eslint-disable-next-line react/prop-types
const Transition = ({ children, location: { pathname } }) => (
	<AnimatePresence>
		<motion.main
			key={pathname}
			variants={variants}
			initial='initial'
			animate='enter'
			exit='exit'>
			{children}
		</motion.main>
	</AnimatePresence>
);

export default Transition;
