import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Provider } from './createContext';

// The provider, which holds the page-wide store and its actions.
// Feel free to abstract actions and state away from this file.
const AppProvider = ({ children = [] }) => {
	const [modalOpen, setModalOpen] = useState(false);
	const [dimensions, setDimensions] = useState({
		width: 0,
		height: 0,
	});
	useEffect(() => {
		setDimensions({
			width: window.innerWidth,
			height: window.innerHeight,
		});
	}, []);
	useEffect(() => {
		const handleResize = () => {
			setDimensions({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		};
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);
	const toggleModal = () => setModalOpen(!modalOpen);
	const state = {
		dimensions,
		modalOpen,
		toggleModal,
	};
	return <Provider value={state}>{children}</Provider>;
};

AppProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AppProvider;
